/**
 * Head Scripts
 *
 * This head scripts file is where you'll write scripts
 * that need to run first. Strictly no jQuery!
 */

require('./vendor/modernizr');

(function() {

    // Your scripts here (no jQuery)

})();
